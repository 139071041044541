<template>
  <div>
    <client-imports></client-imports>
  </div>
</template>

<script>
import ClientImports from "@/components/ClientImports";

export default {
  name: "ClientImportsList",
  components: {
    ClientImports
  }
};
</script>

<style scoped></style>
