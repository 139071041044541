<template>
  <div>
    <!--Process Import Confirmaion-->
    <v-dialog v-model="processImportShow" scrollable persistent max-width="700">
      <v-card flat class="white">
        <v-card-title class="text-h5 secondary--text"
          >Process Client Imports<v-spacer /><v-btn
            text
            rounded
            outlined
            @click="cancelProcessImport()"
            ><v-icon small left>fal fa-times</v-icon>Cancel</v-btn
          ></v-card-title
        >
        <v-card-text>
          Are you sure you want to process all pending client import records?
        </v-card-text>
        <v-card-actions>
          <v-btn block rounded color="primary" @click="processImport()"
            ><v-icon small left>fal fa-check</v-icon>Yes, Run Process</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--Title Bar-->
    <title-bar :title-value="'Client Imports'"></title-bar>

    <!-- Filter -->
    <v-toolbar flat dense>
      <v-menu v-model="filterMenu" offset-y right :close-on-content-click="false">
        <template #activator="{ on }">
          <v-btn rounded color="primary" v-on="on"
            ><v-icon small left>fal fa-filter</v-icon><span v-if="showFiltered()">Filtered</span
            ><span v-else>Filter</span>
          </v-btn>
        </template>

        <v-card max-width="400px">
          <v-card-text>
            <v-container fluid grid-list-sm>
              <!--Clear Filter Button -->
              <v-row dense>
                <v-col cols="12" sm="12" v-if="showFiltered()">
                  <v-btn block rounded color="primary" @click="clearFilter()">
                    <v-icon small left>fal fa-times</v-icon>Clear Filters
                  </v-btn>
                </v-col>
              </v-row>

              <v-row dense>
                <!--Client First Name-->
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="d_clientFirstName"
                    label="Client First Name"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Client Last Name-->
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="d_clientLastName"
                    label="Client Last Name"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Option Start Date-->
                <v-col cols="12" sm="12">
                  <v-menu
                    :close-on-content-click="false"
                    v-model="menu"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template #activator="{ on }">
                      <v-text-field
                        v-on="on"
                        v-model="d_dateImport"
                        label="Import Date (Created >=)"
                        placeholder=" "
                        persistent-placeholder
                        prepend-icon="fal fa-calendar-alt"
                        @blur="date1 = $_parseDate(d_dateImport)"
                      />
                    </template>
                    <v-date-picker
                      v-model="date1"
                      @input="menu = false"
                      color="primary"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <v-row dense>
                <!--Status-->
                <v-col cols="12" sm="12">
                  <v-autocomplete
                    :items="valueListsStore.clientImportStatus"
                    label="Status"
                    placeholder=" "
                    persistent-placeholder
                    item-text="value"
                    item-value="id"
                    v-model="f_status"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <!--Submit Button-->
            <v-btn block rounded color="primary" @click="filter()">
              <v-icon small left>fal fa-check</v-icon>Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>

      <v-spacer />
      <!--Process Import Recorda-->
      <v-tooltip top color="primary">
        <template #activator="{ on }">
          <v-btn icon v-on="on" @click.stop="openProcessImport()">
            <v-icon small color="primary">
              fal fa-play
            </v-icon>
          </v-btn>
        </template>
        <span>Process Imports</span>
      </v-tooltip>

      <client-import-entry />
      <client-import-upload />
    </v-toolbar>

    <!--Pagination  -->
    <pagination
      :show="
        clientImportsStore.clientImports.data && clientImportsStore.clientImports.data.length > 0
      "
      :showTotal="true"
      :currentPage="clientImportsStore.clientImports.current_page"
      :lastPage="clientImportsStore.clientImports.last_page"
      :total="clientImportsStore.clientImports.total"
      v-on:paginate-event="filter($event)"
    />

    <div v-if="progressStore.progress !== true">
      <!--List-->
      <list-basic
        :show="
          clientImportsStore.clientImports.data && clientImportsStore.clientImports.data.length > 0
        "
        :list-data="clientImportsStore.clientImports.data"
        :title="'Client Imports'"
        :section="42"
        @open-entry="openEntry($event)"
        @process-import="processImport($event)"
        @client-detail="clientDetail($event)"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers } from "vuex-map-fields";
import { mixin } from "@/mixins/mixin.js";
import TitleBar from "@/components/TitleBar";
import Pagination from "@/components/Pagination";
import ListBasic from "@/components/ListBasic";
import ClientImportEntry from "@/components/ClientImportEntry";
import ClientImportUpload from "@/components/ClientImportUpload";

const { mapFields } = createHelpers({
  getterType: "clientImportsStore/getFilterField",
  mutationType: "clientImportsStore/updateFilterField"
});

export default {
  name: "ClientImports",
  components: {
    TitleBar,
    Pagination,
    ListBasic,
    ClientImportEntry,
    ClientImportUpload
  },
  mixins: [mixin],
  computed: {
    ...mapState({
      clientImportsStore: state => state.clientImportsStore,
      progressStore: state => state.progressStore,
      valueListsStore: state => state.valueListsStore
    }),
    ...mapFields([
      "page",
      "d_clientFirstName",
      "d_clientLastName",
      "f_status",
      "d_dateImport",
      "date1"
    ])
  },
  watch: {
    date1() {
      this.d_dateImport = this.$_formatDate(this.date1);
    }
  },
  created() {
    this.initialize();
  },
  data() {
    return {
      menu: "",
      filterMenu: false,
      processImportShow: false
    };
  },
  methods: {
    async initialize() {
      this.$store.dispatch("progressStore/set", true);

      const filters = this.$store.getters["clientImportsStore/getFilters"];
      await this.$store.dispatch("clientImportsStore/fetch", filters);

      this.$store.dispatch("progressStore/set", false);
    },

    async filter(page) {
      this.filterMenu = false;
      this.page = page;

      this.initialize();
    },

    clearFilterColumns() {
      this.d_clientFirstName = "";
      this.d_clientLastName = "";
      this.f_status = "";
      this.d_dateImport = "";
      this.date1 = "";
      this.page = 1;
    },

    async clearFilter() {
      this.filterMenu = false;
      this.clearFilterColumns();
      this.initialize();
    },

    showFiltered() {
      if (
        this.d_clientFirstName ||
        this.d_clientLastName ||
        this.f_status ||
        this.f_status === 0 ||
        this.d_dateImport
      ) {
        return true;
      }
      return false;
    },

    async openEntry(id) {
      await this.$store.dispatch("clientImportsStore/entry", id);
    },

    openProcessImport() {
      this.processImportShow = true;
    },

    cancelProcessImport() {
      this.processImportShow = false;
    },

    async processImport(id) {
      let $id = 0;
      if (id) {
        $id = id;
      }
      let data = {
        id: $id
      };
      await this.$store.dispatch("clientImportsStore/process", data);
      this.processImportShow = false;
      /*Show message that import is running*/
      if ($id === 0) {
        const data = {
          color: "success",
          text:
            "Client import records in a Pending Import status are being processed.  Depending on the number of records to process, please refresh the list in a few minutes to monitor the results of the import process."
        };
        this.$store.commit("messagesStore/setMessage", data);
      }
    },

    clientDetail(uuid) {
      let routeData = this.$router.resolve({ name: "client", params: { clientUUID: uuid } });
      window.open(routeData.href, "_blank");
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
