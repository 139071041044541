<template>
  <div>
    <v-tooltip top color="primary">
      <template #activator="{ on }">
        <v-btn icon v-on="on" @click="newEntry()">
          <v-icon color="primary">
            fal fa-user-friends
          </v-icon>
        </v-btn>
      </template>
      <span>Add Client Data Manually</span>
    </v-tooltip>

    <!--Record Entry-->
    <v-dialog v-model="show" scrollable persistent max-width="1050">
      <v-card flat class="white" height="900">
        <v-card-title class="text-h5 secondary--text"
          >Client Import Details<v-spacer /><v-btn text rounded outlined @click="cancelEntry()"
            ><v-icon small left>fal fa-times</v-icon>Cancel</v-btn
          ></v-card-title
        >
        <v-card-text>
          <v-form ref="entryForm" v-model="valid" @submit.prevent="saveEntry()" lazy-validation>
            <v-container fluid>
              <v-tabs grow show-arrows="mobile" slider-color="secondary" v-model="tab">
                <v-tab>Client Details</v-tab>
                <v-tab>Household</v-tab>
                <v-tab>Applicants</v-tab>
                <v-tab>Dependents</v-tab>
                <v-tab>Eligibility</v-tab>

                <!--Client-->
                <v-tab-item>
                  <v-card flat>
                    <v-card-text class="px-0">
                      <v-container fluid>
                        <v-row dense>
                          <!--Left Side-->
                          <v-col cols="12" sm="12" md="6">
                            <v-row dense>
                              <v-col cols="12" sm="12" md="6">
                                <v-text-field
                                  v-model="d_clientFirstName"
                                  label="First Name"
                                  placeholder=" "
                                  persistent-placeholder
                                  :readonly="f_status === 2 || f_status === 5"
                                />
                              </v-col>
                              <v-col cols="12" sm="12" md="6">
                                <v-text-field
                                  v-model="d_clientLastName"
                                  label="Last Name"
                                  placeholder=" "
                                  persistent-placeholder
                                  :readonly="f_status === 2 || f_status === 5"
                                />
                              </v-col>
                            </v-row>

                            <v-row dense>
                              <v-col cols="12" sm="12" md="6">
                                <v-text-field
                                  v-model="d_clientDOB"
                                  label="DOB"
                                  placeholder=" "
                                  persistent-placeholder
                                  :readonly="f_status === 2 || f_status === 5"
                                />
                              </v-col>
                              <v-col cols="12" sm="12" md="6">
                                <v-text-field
                                  v-model="d_gender"
                                  label="Gender"
                                  placeholder=" "
                                  persistent-placeholder
                                  :readonly="f_status === 2 || f_status === 5"
                                />
                              </v-col>
                            </v-row>

                            <v-row dense>
                              <v-col cols="12" sm="12" md="6">
                                <v-autocomplete
                                  :items="valueListsStore.yesNo"
                                  label="US Citizen"
                                  placeholder=" "
                                  persistent-placeholder
                                  item-text="value"
                                  item-value="value"
                                  v-model="d_usCitizen"
                                  :readonly="f_status === 2 || f_status === 5"
                                />
                              </v-col>
                              <v-col cols="12" sm="12" md="6">
                                <v-autocomplete
                                  :items="valueListsStore.yesNo"
                                  label="Hispanic/Latino"
                                  placeholder=" "
                                  persistent-placeholder
                                  item-text="value"
                                  item-value="value"
                                  v-model="d_hispanicLatino"
                                  :readonly="f_status === 2 || f_status === 5"
                                />
                              </v-col>
                            </v-row>

                            <v-row dense>
                              <v-col cols="12" sm="12" md="12">
                                <v-text-field
                                  v-model="d_raceEthnicity"
                                  label="Race/Ethnicity"
                                  placeholder=" "
                                  persistent-placeholder
                                  :readonly="f_status === 2 || f_status === 5"
                                />
                              </v-col>
                            </v-row>
                          </v-col>

                          <!--Right Side-->
                          <v-col cols="12" sm="12" md="6">
                            <v-row dense>
                              <v-col cols="12" sm="12" md="6">
                                <v-text-field
                                  v-model="d_language"
                                  label="Language"
                                  placeholder=" "
                                  persistent-placeholder
                                  :readonly="f_status === 2 || f_status === 5"
                                />
                              </v-col>
                              <v-col cols="12" sm="12" md="6">
                                <v-autocomplete
                                  :items="valueListsStore.yesNo"
                                  label="Special Need"
                                  placeholder=" "
                                  persistent-placeholder
                                  item-text="value"
                                  item-value="value"
                                  v-model="d_specialNeed"
                                  :readonly="f_status === 2 || f_status === 5"
                                />
                              </v-col>
                            </v-row>

                            <v-row dense>
                              <v-col cols="12" sm="12" md="6">
                                <v-autocomplete
                                  :items="valueListsStore.yesNo"
                                  label="Health Insurance Coverage"
                                  placeholder=" "
                                  persistent-placeholder
                                  item-text="value"
                                  item-value="value"
                                  v-model="d_healthInsuranceCoverage"
                                  :readonly="f_status === 2 || f_status === 5"
                                />
                              </v-col>
                              <v-col cols="12" sm="12" md="6">
                                <v-text-field
                                  v-model="d_servicePriority"
                                  label="Service Priority"
                                  placeholder=" "
                                  persistent-placeholder
                                  :readonly="f_status === 2 || f_status === 5"
                                />
                              </v-col>
                            </v-row>

                            <v-row dense>
                              <v-col cols="12" sm="12" md="6">
                                <v-autocomplete
                                  :items="valueListsStore.yesNo"
                                  label="Additional Financial Support"
                                  placeholder=" "
                                  persistent-placeholder
                                  item-text="value"
                                  item-value="value"
                                  v-model="d_additionalFinancialSupport"
                                  :readonly="f_status === 2 || f_status === 5"
                                />
                              </v-col>
                              <v-col cols="12" sm="12" md="6">
                                <v-text-field
                                  v-model="d_supportersName"
                                  label="Supporter's Name"
                                  placeholder=" "
                                  persistent-placeholder
                                  :readonly="f_status === 2 || f_status === 5"
                                />
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>

                        <v-divider /><v-divider /><v-divider />

                        <v-row dense>
                          <v-col cols="12" sm="12" md="2">
                            <v-text-field
                              v-model="fk_clientID"
                              label="Client ID"
                              placeholder=" "
                              persistent-placeholder
                              :readonly="f_status === 2 || f_status === 5"
                            />
                          </v-col>
                          <v-col cols="12" sm="12" md="3">
                            <v-autocomplete
                              :items="valueListsStore.yesNo"
                              label="Ignore Client Duplicate Check"
                              placeholder=" "
                              persistent-placeholder
                              item-text="value"
                              item-value="id"
                              v-model="f_ignoreDuplicateCheck"
                              :readonly="f_status === 2 || f_status === 5"
                            />
                          </v-col>
                          <v-col cols="12" sm="12" md="5">
                            <v-text-field
                              v-model="d_applicationIDsma"
                              label="SMA Application ID"
                              placeholder=" "
                              persistent-placeholder
                              :readonly="f_status === 2 || f_status === 5"
                            />
                          </v-col>
                        </v-row>

                        <v-row dense>
                          <v-col cols="12" sm="12" md="12">
                            <v-textarea
                              rows="2"
                              v-model="d_message"
                              label="Message"
                              placeholder=" "
                              persistent-placeholder
                            />
                          </v-col>
                        </v-row>

                        <v-row dense>
                          <v-col cols="12" sm="12" md="6">
                            <v-autocomplete
                              :items="valueListsStore.clientImportStatus"
                              label="Status"
                              placeholder=" "
                              persistent-placeholder
                              item-text="value"
                              item-value="id"
                              v-model="f_status"
                            />
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <!--Household-->
                <v-tab-item>
                  <v-card flat>
                    <v-card-text>
                      <v-container fluid>
                        <v-row dense>
                          <!--Left Side-->
                          <v-col cols="12" sm="12" md="6">
                            <v-row dense>
                              <v-col cols="12" sm="12" md="3">
                                <v-text-field
                                  v-model="fk_householdID"
                                  label="Household ID"
                                  placeholder=" "
                                  persistent-placeholder
                                  :readonly="f_status === 2 || f_status === 5"
                                />
                              </v-col>
                            </v-row>

                            <v-row dense>
                              <v-col cols="12" sm="12" md="12">
                                <v-text-field
                                  v-model="d_streetAddress"
                                  label="Street Address"
                                  placeholder=" "
                                  persistent-placeholder
                                  :readonly="f_status === 2 || f_status === 5"
                                />
                              </v-col>
                            </v-row>

                            <v-row dense>
                              <v-col cols="12" sm="12" md="12">
                                <v-text-field
                                  v-model="d_city"
                                  label="City"
                                  placeholder=" "
                                  persistent-placeholder
                                  :readonly="f_status === 2 || f_status === 5"
                                />
                              </v-col>
                            </v-row>

                            <v-row dense>
                              <v-col cols="12" sm="12" md="3">
                                <v-text-field
                                  v-model="d_state"
                                  label="State"
                                  placeholder=" "
                                  persistent-placeholder
                                  :readonly="f_status === 2 || f_status === 5"
                                />
                              </v-col>

                              <v-col cols="12" sm="12" md="3">
                                <v-text-field
                                  v-model="d_zipCode"
                                  label="Zip Code"
                                  placeholder=" "
                                  persistent-placeholder
                                  :readonly="f_status === 2 || f_status === 5"
                                />
                              </v-col>

                              <v-col cols="12" sm="12" md="6">
                                <v-text-field
                                  v-model="d_county"
                                  label="County"
                                  placeholder=" "
                                  persistent-placeholder
                                  :readonly="f_status === 2 || f_status === 5"
                                />
                              </v-col>
                            </v-row>
                          </v-col>

                          <!--Right Side-->
                          <v-col cols="12" sm="12" md="6">
                            <v-row dense>
                              <v-col cols="12" sm="12" md="6">
                                <v-text-field
                                  v-model="d_familySize"
                                  label="Family Size"
                                  placeholder=" "
                                  persistent-placeholder
                                  :readonly="f_status === 2 || f_status === 5"
                                />
                              </v-col>

                              <v-col cols="12" sm="12" md="6">
                                <v-text-field
                                  v-model="d_familyStructure"
                                  label="Family Structure"
                                  placeholder=" "
                                  persistent-placeholder
                                  :readonly="f_status === 2 || f_status === 5"
                                />
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <!--Applicants-->
                <v-tab-item>
                  <v-card flat>
                    <v-card-text>
                      <v-container fluid>
                        <v-row dense>
                          <!--Left Side-->
                          <v-col cols="12" sm="12" md="6">
                            <v-row dense>
                              <v-col cols="12" sm="12" md="4">
                                <v-text-field
                                  v-model="fk_applicantID_Primary"
                                  label="Primary Applicant ID"
                                  placeholder=" "
                                  persistent-placeholder
                                  :readonly="f_status === 2 || f_status === 5"
                                />
                              </v-col>
                            </v-row>
                            <v-row dense>
                              <v-col cols="12" sm="12" md="6">
                                <v-text-field
                                  v-model="d_primaryFirstName"
                                  label="Primary First Name"
                                  placeholder=" "
                                  persistent-placeholder
                                  :readonly="f_status === 2 || f_status === 5"
                                />
                              </v-col>
                              <v-col cols="12" sm="12" md="6">
                                <v-text-field
                                  v-model="d_primaryLastName"
                                  label="Primary Last Name"
                                  placeholder=" "
                                  persistent-placeholder
                                  :readonly="f_status === 2 || f_status === 5"
                                />
                              </v-col>
                            </v-row>

                            <v-row dense>
                              <v-col cols="12" sm="12" md="6">
                                <v-text-field
                                  v-model="d_primaryDOB"
                                  label="Primary DOB"
                                  placeholder=" "
                                  persistent-placeholder
                                  :readonly="f_status === 2 || f_status === 5"
                                />
                              </v-col>
                            </v-row>

                            <v-row dense>
                              <v-col cols="12" sm="12" md="12">
                                <v-text-field
                                  v-model="d_primaryRaceEthnicity"
                                  label="Primary Race/Ethnicity"
                                  placeholder=" "
                                  persistent-placeholder
                                  :readonly="f_status === 2 || f_status === 5"
                                />
                              </v-col>
                            </v-row>

                            <v-row dense>
                              <v-col cols="12" sm="12" md="6">
                                <v-text-field
                                  v-model="d_primaryPhone1"
                                  label="Primary Phone #1"
                                  placeholder=" "
                                  persistent-placeholder
                                  :readonly="f_status === 2 || f_status === 5"
                                />
                              </v-col>
                              <v-col cols="12" sm="12" md="6">
                                <v-text-field
                                  v-model="d_primaryPhone2"
                                  label="Primary Phone #2"
                                  placeholder=" "
                                  persistent-placeholder
                                  :readonly="f_status === 2 || f_status === 5"
                                />
                              </v-col>
                            </v-row>

                            <v-row dense>
                              <v-col cols="12" sm="12" md="12">
                                <v-text-field
                                  v-model="d_primaryEMailAddress"
                                  label="Primary Email"
                                  placeholder=" "
                                  persistent-placeholder
                                  :readonly="f_status === 2 || f_status === 5"
                                />
                              </v-col>
                            </v-row>

                            <v-row dense>
                              <v-col cols="12" sm="12" md="12">
                                <v-text-field
                                  v-model="d_primaryRelationshipToChild"
                                  label="Primary Relationship To Child"
                                  placeholder=" "
                                  persistent-placeholder
                                  :readonly="f_status === 2 || f_status === 5"
                                />
                              </v-col>
                            </v-row>
                          </v-col>

                          <!--Right Side-->
                          <v-col cols="12" sm="12" md="6">
                            <v-row dense>
                              <v-col cols="12" sm="12" md="5">
                                <v-text-field
                                  v-model="fk_applicantID_OPG"
                                  label="Other Parent/Guardian ID"
                                  placeholder=" "
                                  persistent-placeholder
                                  :readonly="f_status === 2 || f_status === 5"
                                />
                              </v-col>
                            </v-row>
                            <v-row dense>
                              <v-col cols="12" sm="12" md="6">
                                <v-text-field
                                  v-model="d_opgFirstName"
                                  label="Other Parent/Guardian First Name"
                                  placeholder=" "
                                  persistent-placeholder
                                  :readonly="f_status === 2 || f_status === 5"
                                />
                              </v-col>
                              <v-col cols="12" sm="12" md="6">
                                <v-text-field
                                  v-model="d_opgLastName"
                                  label="Other Parent/Guardian Last Name"
                                  placeholder=" "
                                  persistent-placeholder
                                  :readonly="f_status === 2 || f_status === 5"
                                />
                              </v-col>
                            </v-row>

                            <v-row dense>
                              <v-col cols="12" sm="12" md="6">
                                <v-text-field
                                  v-model="d_opgDOB"
                                  label="Other Parent/Guardian DOB"
                                  placeholder=" "
                                  persistent-placeholder
                                  :readonly="f_status === 2 || f_status === 5"
                                />
                              </v-col>
                            </v-row>

                            <v-row dense>
                              <v-col cols="12" sm="12" md="12">
                                <v-text-field
                                  v-model="d_opgRaceEthnicity"
                                  label="Other Parent/Guardian Race/Ethnicity"
                                  placeholder=" "
                                  persistent-placeholder
                                  :readonly="f_status === 2 || f_status === 5"
                                />
                              </v-col>
                            </v-row>

                            <v-row dense>
                              <v-col cols="12" sm="12" md="6">
                                <v-text-field
                                  v-model="d_opgPhone1"
                                  label="Other Parent/Guardian Phone #1"
                                  placeholder=" "
                                  persistent-placeholder
                                  :readonly="f_status === 2 || f_status === 5"
                                />
                              </v-col>
                              <v-col cols="12" sm="12" md="6">
                                <v-text-field
                                  v-model="d_opgPhone2"
                                  label="Other Parent/Guardian Phone #2"
                                  placeholder=" "
                                  persistent-placeholder
                                  :readonly="f_status === 2 || f_status === 5"
                                />
                              </v-col>
                            </v-row>

                            <v-row dense>
                              <v-col cols="12" sm="12" md="12">
                                <v-text-field
                                  v-model="d_opgEMailAddress"
                                  label="Other Parent/Guardian Email"
                                  placeholder=" "
                                  persistent-placeholder
                                  :readonly="f_status === 2 || f_status === 5"
                                />
                              </v-col>
                            </v-row>

                            <v-row dense>
                              <v-col cols="12" sm="12" md="12">
                                <v-text-field
                                  v-model="d_opgRelationshipToChild"
                                  label="Other Parent/Guardian Relationship To Child"
                                  placeholder=" "
                                  persistent-placeholder
                                  :readonly="f_status === 2 || f_status === 5"
                                />
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <!--Dependents-->
                <v-tab-item>
                  <v-card flat>
                    <v-card-text>
                      <v-container fluid>
                        <v-row dense>
                          <v-col cols="12" sm="12" md="2">
                            <v-text-field
                              v-model="fk_dependentID1"
                              label="Dependent #1 ID"
                              placeholder=" "
                              persistent-placeholder
                              :readonly="f_status === 2 || f_status === 5"
                            />
                          </v-col>
                          <v-col cols="12" sm="12" md="4">
                            <v-text-field
                              v-model="d_dependent1Name"
                              label="Dependent #1 Name"
                              placeholder=" "
                              persistent-placeholder
                              :readonly="f_status === 2 || f_status === 5"
                            />
                          </v-col>
                          <v-col cols="12" sm="12" md="6">
                            <v-text-field
                              v-model="d_dependent1DOB"
                              label="Dependent #1 DOB"
                              placeholder=" "
                              persistent-placeholder
                              :readonly="f_status === 2 || f_status === 5"
                            />
                          </v-col>
                        </v-row>
                        <v-row dense>
                          <v-col cols="12" sm="12" md="6">
                            <v-text-field
                              v-model="d_dependent1RelationshipToApplicant"
                              label="Dependent #1 Relationship To Applicant"
                              placeholder=" "
                              persistent-placeholder
                              :readonly="f_status === 2 || f_status === 5"
                            />
                          </v-col>
                          <v-col cols="12" sm="12" md="6">
                            <v-text-field
                              v-model="d_dependent1RelatedTo"
                              label="Dependent #1 Related To"
                              placeholder=" "
                              persistent-placeholder
                              :readonly="f_status === 2 || f_status === 5"
                            />
                          </v-col>
                        </v-row>
                        <v-divider /><v-divider /><v-divider />

                        <v-row dense>
                          <v-col cols="12" sm="12" md="2">
                            <v-text-field
                              v-model="fk_dependentID2"
                              label="Dependent #2 ID"
                              placeholder=" "
                              persistent-placeholder
                              :readonly="f_status === 2 || f_status === 5"
                            />
                          </v-col>
                          <v-col cols="12" sm="12" md="4">
                            <v-text-field
                              v-model="d_dependent2Name"
                              label="Dependent #2 Name"
                              placeholder=" "
                              persistent-placeholder
                              :readonly="f_status === 2 || f_status === 5"
                            />
                          </v-col>
                          <v-col cols="12" sm="12" md="6">
                            <v-text-field
                              v-model="d_dependent2DOB"
                              label="Dependent #2 DOB"
                              placeholder=" "
                              persistent-placeholder
                              :readonly="f_status === 2 || f_status === 5"
                            />
                          </v-col>
                        </v-row>
                        <v-row dense>
                          <v-col cols="12" sm="12" md="6">
                            <v-text-field
                              v-model="d_dependent2RelationshipToApplicant"
                              label="Dependent #2 Relationship To Applicant"
                              placeholder=" "
                              persistent-placeholder
                              :readonly="f_status === 2 || f_status === 5"
                            />
                          </v-col>
                          <v-col cols="12" sm="12" md="6">
                            <v-text-field
                              v-model="d_dependent2RelatedTo"
                              label="Dependent #2 Related To"
                              placeholder=" "
                              persistent-placeholder
                              :readonly="f_status === 2 || f_status === 5"
                            />
                          </v-col>
                        </v-row>
                        <v-divider /><v-divider /><v-divider />

                        <v-row dense>
                          <v-col cols="12" sm="12" md="2">
                            <v-text-field
                              v-model="fk_dependentID3"
                              label="Dependent #3 ID"
                              placeholder=" "
                              persistent-placeholder
                              :readonly="f_status === 2 || f_status === 5"
                            />
                          </v-col>
                          <v-col cols="12" sm="12" md="4">
                            <v-text-field
                              v-model="d_dependent3Name"
                              label="Dependent #3 Name"
                              placeholder=" "
                              persistent-placeholder
                              :readonly="f_status === 2 || f_status === 5"
                            />
                          </v-col>
                          <v-col cols="12" sm="12" md="6">
                            <v-text-field
                              v-model="d_dependent3DOB"
                              label="Dependent #3 DOB"
                              placeholder=" "
                              persistent-placeholder
                              :readonly="f_status === 2 || f_status === 5"
                            />
                          </v-col>
                        </v-row>
                        <v-row dense>
                          <v-col cols="12" sm="12" md="6">
                            <v-text-field
                              v-model="d_dependent3RelationshipToApplicant"
                              label="Dependent #3 Relationship To Applicant"
                              placeholder=" "
                              persistent-placeholder
                              :readonly="f_status === 2 || f_status === 5"
                            />
                          </v-col>
                          <v-col cols="12" sm="12" md="6">
                            <v-text-field
                              v-model="d_dependent3RelatedTo"
                              label="Dependent #3 Related To"
                              placeholder=" "
                              persistent-placeholder
                              :readonly="f_status === 2 || f_status === 5"
                            />
                          </v-col>
                        </v-row>
                        <v-divider /><v-divider /><v-divider />

                        <v-row dense>
                          <v-col cols="12" sm="12" md="2">
                            <v-text-field
                              v-model="fk_dependentID4"
                              label="Dependent #4 ID"
                              placeholder=" "
                              persistent-placeholder
                              :readonly="f_status === 2 || f_status === 5"
                            />
                          </v-col>
                          <v-col cols="12" sm="12" md="4">
                            <v-text-field
                              v-model="d_dependent4Name"
                              label="Dependent #4 Name"
                              placeholder=" "
                              persistent-placeholder
                              :readonly="f_status === 2 || f_status === 5"
                            />
                          </v-col>
                          <v-col cols="12" sm="12" md="6">
                            <v-text-field
                              v-model="d_dependent4DOB"
                              label="Dependent #4 DOB"
                              placeholder=" "
                              persistent-placeholder
                              :readonly="f_status === 2 || f_status === 5"
                            />
                          </v-col>
                        </v-row>
                        <v-row dense>
                          <v-col cols="12" sm="12" md="6">
                            <v-text-field
                              v-model="d_dependent4RelationshipToApplicant"
                              label="Dependent #4 Relationship To Applicant"
                              placeholder=" "
                              persistent-placeholder
                              :readonly="f_status === 2 || f_status === 5"
                            />
                          </v-col>
                          <v-col cols="12" sm="12" md="6">
                            <v-text-field
                              v-model="d_dependent4RelatedTo"
                              label="Dependent #4 Related To"
                              placeholder=" "
                              persistent-placeholder
                              :readonly="f_status === 2 || f_status === 5"
                            />
                          </v-col>
                        </v-row>
                        <v-divider /><v-divider /><v-divider />

                        <v-row dense>
                          <v-col cols="12" sm="12" md="2">
                            <v-text-field
                              v-model="fk_dependentID5"
                              label="Dependent #5 ID"
                              placeholder=" "
                              persistent-placeholder
                              :readonly="f_status === 2 || f_status === 5"
                            />
                          </v-col>
                          <v-col cols="12" sm="12" md="4">
                            <v-text-field
                              v-model="d_dependent5Name"
                              label="Dependent #5 Name"
                              placeholder=" "
                              persistent-placeholder
                              :readonly="f_status === 2 || f_status === 5"
                            />
                          </v-col>
                          <v-col cols="12" sm="12" md="6">
                            <v-text-field
                              v-model="d_dependent5DOB"
                              label="Dependent #5 DOB"
                              placeholder=" "
                              persistent-placeholder
                              :readonly="f_status === 2 || f_status === 5"
                            />
                          </v-col>
                        </v-row>
                        <v-row dense>
                          <v-col cols="12" sm="12" md="6">
                            <v-text-field
                              v-model="d_dependent5RelationshipToApplicant"
                              label="Dependent #5 Relationship To Applicant"
                              placeholder=" "
                              persistent-placeholder
                              :readonly="f_status === 2 || f_status === 5"
                            />
                          </v-col>
                          <v-col cols="12" sm="12" md="6">
                            <v-text-field
                              v-model="d_dependent5RelatedTo"
                              label="Dependent #5 Related To"
                              placeholder=" "
                              persistent-placeholder
                              :readonly="f_status === 2 || f_status === 5"
                            />
                          </v-col>
                        </v-row>
                        <!--<v-divider /><v-divider /><v-divider />

                        <v-row dense>
                          <v-col cols="12" sm="12" md="2">
                            <v-text-field
                              v-model="fk_dependentID6"
                              label="Dependent #6 ID"
                              placeholder=" "
                              persistent-placeholder
                              :readonly="f_status === 2 || f_status === 5"
                            />
                          </v-col>
                          <v-col cols="12" sm="12" md="4">
                            <v-text-field
                              v-model="d_dependent6Name"
                              label="Dependent #6 Name"
                              placeholder=" "
                              persistent-placeholder
                              :readonly="f_status === 2 || f_status === 5"
                            />
                          </v-col>
                          <v-col cols="12" sm="12" md="6">
                            <v-text-field
                              v-model="d_dependent6DOB"
                              label="Dependent #6 DOB"
                              placeholder=" "
                              persistent-placeholder
                              :readonly="f_status === 2 || f_status === 5"
                            />
                          </v-col>
                        </v-row>
                        <v-row dense>
                          <v-col cols="12" sm="12" md="6">
                            <v-text-field
                              v-model="d_dependent6RelationshipToApplicant"
                              label="Dependent #6 Relationship To Applicant"
                              placeholder=" "
                              persistent-placeholder
                              :readonly="f_status === 2 || f_status === 5"
                            />
                          </v-col>
                          <v-col cols="12" sm="12" md="6">
                            <v-text-field
                              v-model="d_dependent6RelatedTo"
                              label="Dependent #6 Related To"
                              placeholder=" "
                              persistent-placeholder
                              :readonly="f_status === 2 || f_status === 5"
                            />
                          </v-col>
                        </v-row>-->
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <!--Eligibility-->
                <v-tab-item>
                  <v-card flat>
                    <v-card-text>
                      <v-container fluid>
                        <v-row dense>
                          <!--Left Side-->
                          <v-col cols="12" sm="12" md="6">
                            <v-row dense>
                              <v-col cols="12" sm="12" md="12">
                                <v-text-field
                                  v-model="d_employer"
                                  label="Employer"
                                  placeholder=" "
                                  persistent-placeholder
                                  :readonly="f_status === 2 || f_status === 5"
                                />
                              </v-col>
                            </v-row>

                            <v-row dense>
                              <v-col cols="12" sm="12" md="6">
                                <v-text-field
                                  v-model="d_units"
                                  label="Units"
                                  placeholder=" "
                                  persistent-placeholder
                                  :readonly="f_status === 2 || f_status === 5"
                                />
                              </v-col>
                              <v-col cols="12" sm="12" md="6">
                                <v-text-field
                                  v-model="d_income"
                                  label="Income"
                                  placeholder=" "
                                  persistent-placeholder
                                  :readonly="f_status === 2 || f_status === 5"
                                />
                              </v-col>
                            </v-row>

                            <v-row dense>
                              <v-col cols="12" sm="12" md="12">
                                <v-text-field
                                  v-model="d_documentation"
                                  label="Documentation"
                                  placeholder=" "
                                  persistent-placeholder
                                  :readonly="f_status === 2 || f_status === 5"
                                />
                              </v-col>
                            </v-row>

                            <v-row dense>
                              <v-col cols="12" sm="12" md="6">
                                <v-autocomplete
                                  :items="valueListsStore.yesNo"
                                  label="Placeholder"
                                  item-text="value"
                                  item-value="value"
                                  v-model="d_placeholder"
                                  :readonly="f_status === 2 || f_status === 5"
                                  placeholder=" "
                                  persistent-placeholder
                                />
                              </v-col>
                              <v-col cols="12" sm="12" md="6">
                                <v-text-field
                                  v-model="d_placeholderSeatID"
                                  label="Placeholder Seat ID"
                                  placeholder=" "
                                  persistent-placeholder
                                  :readonly="f_status === 2 || f_status === 5"
                                />
                              </v-col>
                            </v-row>
                          </v-col>

                          <!--Right Side-->
                          <v-col cols="12" sm="12" md="6"> </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn block rounded color="primary" @click="saveEntry()"
            ><v-icon small left>fal fa-check</v-icon>Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers } from "vuex-map-fields";

import { mixin } from "@/mixins/mixin.js";

const { mapFields } = createHelpers({
  getterType: "clientImportsStore/getEntryField",
  mutationType: "clientImportsStore/updateEntryField"
});

export default {
  name: "ClientImportEntry",
  mixins: [mixin],
  computed: {
    ...mapState({
      clientImportsStore: state => state.clientImportsStore,
      valueListsStore: state => state.valueListsStore,
      usersStore: state => state.usersStore
    }),
    ...mapFields([
      "show",
      "valid",
      "mode",
      "id",
      "fk_householdID",
      "fk_applicantID_Primary",
      "fk_applicantID_OPG",
      "fk_dependentID1",
      "fk_dependentID2",
      "fk_dependentID3",
      "fk_dependentID4",
      "fk_dependentID5",
      //"fk_dependentID6",
      "fk_clientID",
      "d_clientFirstName",
      "d_clientLastName",
      "d_clientDOB",
      "d_gender",
      "d_usCitizen",
      "d_race",
      "d_hispanicLatino",
      "d_raceEthnicity",
      "d_language",
      "d_specialNeed",
      "d_healthInsuranceCoverage",
      "d_servicePriority",
      "d_additionalFinancialSupport",
      "d_supportersName",
      "d_streetAddress",
      "d_city",
      "d_state",
      "d_zipCode",
      "d_county",
      "d_familySize",
      "d_familyStructure",
      "d_primaryFirstName",
      "d_primaryLastName",
      "d_primaryDOB",
      "d_primaryRaceEthnicity",
      "d_primaryPhone1",
      "d_primaryPhone2",
      "d_primaryEMailAddress",
      "d_primaryRelationshipToChild",
      "d_opgFirstName",
      "d_opgLastName",
      "d_opgDOB",
      "d_opgRaceEthnicity",
      "d_opgPhone1",
      "d_opgPhone2",
      "d_opgEMailAddress",
      "d_opgRelationshipToChild",
      "d_dependent1Name",
      "d_dependent1DOB",
      "d_dependent1RelationshipToApplicant",
      "d_dependent1RelatedTo",
      "d_dependent2Name",
      "d_dependent2DOB",
      "d_dependent2RelationshipToApplicant",
      "d_dependent2RelatedTo",
      "d_dependent3Name",
      "d_dependent3DOB",
      "d_dependent3RelationshipToApplicant",
      "d_dependent3RelatedTo",
      "d_dependent4Name",
      "d_dependent4DOB",
      "d_dependent4RelationshipToApplicant",
      "d_dependent4RelatedTo",
      "d_dependent5Name",
      "d_dependent5DOB",
      "d_dependent5RelationshipToApplicant",
      "d_dependent5RelatedTo",
      //"d_dependent6Name",
      //"d_dependent6DOB",
      //"d_dependent6RelationshipToApplicant",
      //"d_dependent6RelatedTo",
      "d_employer",
      "d_units",
      "d_income",
      "d_documentation",
      "d_placeholder",
      "d_placeholderSeatID",
      "d_applicationIDsma",
      "d_message",
      "f_ignoreDuplicateCheck",
      "f_status",
      "f_show"
    ])
  },
  data() {
    return {
      tab: 0
    };
  },
  methods: {
    resetEntry() {
      this.$store.dispatch("clientImportsStore/resetEntry");
    },

    async newEntry() {
      this.resetEntry();

      this.tab = 0;
      this.mode = 1;
      this.f_status = 1;
      this.show = true;
    },

    cancelEntry() {
      this.tab = 0;
      this.show = false;
      this.resetEntry();
    },

    async saveEntry() {
      if (this.$refs.entryForm.validate()) {
        const entry = this.$store.getters["clientImportsStore/getEntry"];
        /* Create Record */
        if (this.mode === 1) {
          await this.$store.dispatch("clientImportsStore/create", entry);
        } else {
          /* Edit Record */
          await this.$store.dispatch("clientImportsStore/update", entry);
        }
        this.cancelEntry();
      }
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
